export function MediaItem({ slide }) {
  return (
    <div className="media">
      {slide.type == "video" ? (
        <video
          src={`https://letmestyle.com${slide.video_url}`}
          type="vide/webm"
          loop
          autoPlay={true}
          playsInline
          muted
          style={{
            width: "auto",
            height: "100svh",
            objectFit: "cover",
          }}
        ></video>
      ) : (
        <img
          className="media_content"
          src={slide.pic}
          alt="Slide Picture"
          style={{
            width: "100%",
            height: "100svh",
            objectFit: "cover",
          }}
        />
      )}
    </div>
  );
}
