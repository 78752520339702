import React, { useState, useEffect, useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Mousewheel, Pagination, Navigation } from "swiper";
import "swiper/swiper-bundle.css";
import "swiper/css/navigation";
import { useDispatch, useSelector } from "react-redux";
import { apiRequest } from "utils/api";

import { toggleAuthorize, toggleSettings } from "features/auth/authSlice";
import {
  updatePublisherId,
  updateSlidePage,
} from "features/userProfile/userProfileSlice";

import { setIsLoading } from "features/loading/loadingSlice";
import AudioComment from "components/AudioComment/AudioComment";
import ReportLightbox from "./ReportLightbox";
import ShareLightbox from "./ShareLightbox";
import "./styles.css";
import {
  AuthorSection,
  LikesSection,
  MediaItem,
  RightControls,
} from "components/Screen";
import {
  CloseSvg,
  HideSvg,
  PhotoSvg,
  SettingsMenuSvg,
  ShowSvg,
} from "components/Svg";
import { actions } from "utils/apiActions";

// Ініціалізація додаткових модулів Swiper
SwiperCore.use([Mousewheel, Pagination]);

function Swiper_block({ setCameraOn }) {
  const [slides, setSlides] = useState([]);
  const [page, setPage] = useState(1);
  const [isUIVisible, setIsUIVisible] = useState(true); // State for UI visibility
  const [activeIndex, setActiveIndex] = useState(0);
  const [currentPostId, setCurrentPostId] = useState(null);
  const [audioComments, setAudioComments] = useState(false);
  const [reportedPostId, setReportedPostId] = useState(null);
  const [isReportBoxVisible, setIsReportBoxVisible] = useState(false);
  const [isShareBoxVisible, setIsShareBoxVisible] = useState(false);

  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const publisherId = useSelector((state) => state.user.slidesPublisherId);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();

  const setPosts = async (publisherId, page, post_id) => {
    const posts = await actions.getPosts(publisherId, page);

    if (!id) {
      setSlides(posts);
      setCurrentPostId(posts[0].id);
      navigate(`/${posts[0]?.id}`, { replace: true });
      return;
    }

    const linkPost = await actions.getPost(publisherId, post_id);

    if (posts && posts.length) {
      const filteredPosts = posts.filter((item) => item.id !== linkPost[0]?.id);
      setSlides([...linkPost, ...filteredPosts]);
      setCurrentPostId(linkPost.id);
    }
  };

  useEffect(() => {
    setPosts(publisherId, page, id);
    dispatch(setIsLoading(false));
  }, []);

  const handleSettingsClick = () => {
    if (isAuthenticated) {
      dispatch(toggleSettings());
    } else {
      dispatch(toggleAuthorize());
    }
  };

  const fetchSlides = async (currentPage) => {
    try {
      const id = publisherId == -1 ? "" : publisherId;
      const slides = await actions.getPosts(id, currentPage);

      if (slides && slides.length) {
        setSlides((prevSlides) => [...prevSlides, ...slides]);
        setPage(currentPage);
        setCurrentPostId(slides[0].id);
      }
    } catch (error) {
      console.error("Failed to fetch slides:", error);
    }
  };

  const handleSlideChange = (swiper) => {
    if (swiper.activeIndex + 2 >= slides.length) {
      fetchSlides(page + 1); // Завантаження наступної сторінки слайдів
    }

    const newId = slides[swiper.activeIndex].id;
    navigate(`/${newId}`, { replace: true });
  };

  const handleReportClick = (postId) => {
    setReportedPostId(postId);
    setIsReportBoxVisible(true);
  };

  const handleReportSubmit = async (data) => {
    try {
      // Make an API request to report the post
      const response = await apiRequest("report_post.php", "POST", {
        post_id: data.postId, // Ensure this is the ID of the post being reported
        reason: data.reason, // The reason selected by the user
        additional_info: data.additionalInfo || "", // Optional additional information
      });

      if (response.status === "success") {
        // Handle success response
        console.log("Report submitted successfully:", response);
        //alert('Your report has been submitted successfully.');
      } else {
        // Handle error response
        console.error("Failed to submit report:", response.message);
        //alert(`Failed to submit report: ${response.message}`);
      }
    } catch (error) {
      // Handle any errors
      console.error("Error while submitting report:", error);
      //alert('An error occurred while submitting your report. Please try again later.');
    }
    setIsReportBoxVisible(false);
  };

  return (
    <>
      {audioComments && (
        <AudioComment
          setAudioComments={setAudioComments}
          postId={currentPostId}
        />
      )}

      {isReportBoxVisible && (
        <ReportLightbox
          postId={reportedPostId}
          onClose={() => setIsReportBoxVisible(false)}
          onSubmit={handleReportSubmit}
        />
      )}
      {isShareBoxVisible && (
        <ShareLightbox onClose={() => setIsShareBoxVisible(false)} />
      )}

      <Swiper
        modules={[Navigation]}
        navigation
        direction={"vertical"}
        slidesPerView={1}
        mousewheel={{ enabled: true }}
        spaceBetween={0}
        className="mySwiper"
        id="content"
        initialSlide={activeIndex}
        onSlideChange={handleSlideChange}
      >
        {activeIndex}
        <div className="top_nav">
          <div
            className="button_left"
            onClick={() => {
              if (publisherId == -1) setCameraOn(true);
              else {
                dispatch(updatePublisherId(-1));
                dispatch(updateSlidePage(1));
              }
            }}
            style={{ transform: "scale(1.3)" }}
          >
            {isUIVisible && (publisherId == -1 ? <PhotoSvg /> : <CloseSvg />)}
          </div>

          <div className="button_right">
            <div
              className="hide_interface"
              onClick={() => setIsUIVisible((prev) => !prev)}
            >
              {isUIVisible ? <ShowSvg /> : <HideSvg />}
            </div>
            <SettingsMenuSvg handleSettingsClick={handleSettingsClick} />
          </div>
        </div>
        {slides.map((slide, index) => {
          return (
            <SwiperSlide
              key={index}
              className="post"
              style={{ height: "100svh" }}
              data-id={slide.id}
            >
              <MediaItem slide={slide} />

              {isUIVisible && (
                <>
                  <AuthorSection
                    slide={slide}
                    handleReportClick={handleReportClick}
                  />

                  <div
                    className="interface default hideable"
                    style={{ height: "calc(100% - 95px)" }}
                  >
                    <RightControls
                      slide={slide}
                      setAudioComments={setAudioComments}
                      activeIndex={activeIndex}
                      setIsShareBoxVisible={setIsShareBoxVisible}
                    />
                    <LikesSection slide={slide} activeIndex={activeIndex} />
                  </div>
                </>
              )}
            </SwiperSlide>
          );
        })}
      </Swiper>
    </>
  );
}

export default Swiper_block;
