import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import {
  toggleUserProfile,
  setUser,
} from "features/userProfile/userProfileSlice";
import { toggleAuthorize } from "features/auth/authSlice";

export function AuthorSection({ slide, handleReportClick }) {
  const { t } = useTranslation();
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const dispatch = useDispatch();

  const handleUserProfileClick = (userId) => {
    if (isAuthenticated) {
      dispatch(setUser(userId));
      dispatch(toggleUserProfile());
    } else {
      dispatch(toggleAuthorize());
    }
  };

  return (
    <div className="author_comment hideable">
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <a
          className="user_profile"
          data-userid={slide.user_id}
          onClick={() => handleUserProfileClick(slide.user_id)}
        >
          <img
            src={slide.avatar || "/img/icons/default_avatar.png"}
            alt="User Avatar"
          />
          <span>{slide.nickname}</span>
        </a>
        <span
          className="report"
          data-postid={`${slide.id}`}
          onClick={() => handleReportClick(slide.id)}
        >
          {t("system.report")}
        </span>
      </div>
      <div className="com_text_button">
        <div>{slide.description}</div>
      </div>
    </div>
  );
}
