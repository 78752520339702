export const TextSvg = () => {
  return (
    <svg
      width="27"
      height="27"
      className="svg-shadow"
      xmlns="http://www.w3.org/2000/svg"
      style={{
        fillRule: "evenodd",
        clipRule: "evenodd",
      }}
      viewBox="0 0 17000 15000"
    >
      <path
        className="fil0"
        d="M7711.23 181.84c-2057.47,201.96 -3444.86,691.19 -5006.38,1842.2 -682.02,502.73 -1309.62,1235.05 -1767.27,1949.58 -929.91,1451.87 -1111.7,3485.62 -478.47,5074.04 234.31,587.74 435.56,922.89 736.38,1373.18 393.7,589.31 614.26,318.14 264.4,1476.81 -541.7,1794.03 -1686.38,2146.22 -1311.91,2750.57 178.13,287.48 402.98,268.85 783.13,231.68 1474.12,-144.11 2658.57,-610.01 3872.22,-1414.32 518.6,-343.68 118.36,-150.63 1553.15,163.74 2449.45,536.66 5066.54,76.83 6982.37,-1003.79 3000.15,-1692.25 4609.89,-5082.8 2908.45,-8349.83 -257.74,-494.91 -557.87,-879.93 -856.9,-1251.79 -147.54,-183.48 -353.75,-336.24 -520.81,-518.06 -713.47,-776.51 -2210.86,-1554.8 -3370.41,-1919.19 -1070.15,-336.29 -2608.66,-520.58 -3787.95,-404.82zm-3662.18 5790.29c-1343.54,306.01 -931.75,2375.98 502.58,2052.49 1312.56,-296.02 900.18,-2371.99 -502.58,-2052.49zm4219.32 0c-1343.54,306.01 -931.75,2375.98 502.59,2052.49 1312.55,-296.02 900.17,-2371.99 -502.59,-2052.49zm4219.31 0c-1343.53,306.01 -931.73,2375.98 502.6,2052.49 521.04,-117.51 917.95,-617.04 764.04,-1288.83 -120.93,-527.87 -640,-906.38 -1266.64,-763.66z"
      ></path>
    </svg>
  );
};
