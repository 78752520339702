import { useEffect, useState } from "react";
import { actions } from "utils/apiActions";
import { HeartSet, HeartUnset } from "../../Svg";

export const LikeHeart = ({ comment }) => {
  const [isLiked, setIsLiked] = useState(false);
  const [count, setCount] = useState(0);

  useEffect(() => {
    setIsLiked(comment.isLiked);
    setCount(comment.like_count);
  }, []);

  const handleLikeComment = async (commentId, isLiked) => {
    const newIsLiked = !isLiked;
    const data = await actions.likComment(commentId, newIsLiked);

    if (data) {
      setIsLiked(newIsLiked);
      setCount(data.like_count);
    }
  };

  return (
    <>
      <div
        className="hearts"
        data-comment_id={comment.id}
        onClick={() => handleLikeComment(comment.id, isLiked)}
      >
        {isLiked ? <HeartSet /> : <HeartUnset />}
      </div>
      <div className="like_count">{count}</div>
    </>
  );
};
