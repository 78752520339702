import React from "react";
import { CommentWrapper } from ".";

export const CommentsList = ({ comments, error, setComments }) => {
  if (error) {
    return <div className="no_comments">{error}</div>;
  }
  const parentComments = comments.filter((comment) => comment.parent === 0);
  return parentComments.map((comment) => (
    <React.Fragment key={comment.id}>
      <CommentWrapper
        comment={comment}
        comments={comments}
        setComments={setComments}
      />
    </React.Fragment>
  ));
};
