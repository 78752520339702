export const DislikeSvg = ({ slide }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      style={{ fillRule: "evenodd", clipRule: "evenodd" }}
      viewBox="0 0 4300 4400"
    >
      <defs>
        <linearGradient id="dislike-gradient" gradientTransform="rotate(30)">
          <stop offset="0%" stopColor="#e70489"></stop>
          <stop offset="100%" stopColor="#fa5f6f"></stop>
        </linearGradient>
      </defs>
      <path
        className={`gradientable5${slide.id}`}
        d="M3171.6 2151.48c0,229.3 -282.23,471.84 -423.34,707.76 -134.79,191.66 -322.78,483.78 -328.13,815.49 -5.35,331.71 139.6,718.81 -157.97,708.89 -584,-19.47 -728.75,-1029.48 -473.01,-1514.46 -241.52,0 -1072.6,-0.02 -1216.93,-0.02 -563.76,0 -604.06,-573.82 -287.51,-726.52 -386.41,-124.16 -365.4,-676.58 64.92,-733.18 -204.24,-142.4 -213.37,-582.48 280.23,-638.87 -192.35,-204.47 -159.8,-643.16 603.66,-643.16l1435.37 0c256.86,0 502.71,133.95 502.71,416.72 0,535.78 0,1071.57 0,1607.35zm994.48 211.66l-566.82 0c-71.5,0 -130,-58.5 -130,-130l0 -2061.72c0,-71.5 58.5,-130 130,-130l566.82 0c71.5,0 130,58.5 130,130l0 2061.72c0,71.5 -58.5,130 -130,130z"
      ></path>
    </svg>
  );
};
