const resources = {
  en: {
    langName: "English",
    translation: {
      auth: "Authorization",
      login: "Login",
      email: "Email",
      pass: "Password",
      create: "Create account",
      forgot: "Forgot Password",
      nickname: "Nickname",
      interface_lang: "Interface language",
      gender: "Your gender",
      man: "Man",
      woman: "Woman",
      it: "I don't want to specify.",
      birthday: "Your birthday date",
      posts: "Posts",
      followers: "Followers",
      in_favorites: "In favorites",
      available: "available from mobile",
      edit_profile: "Edit Profile",
      logout: "Log Out",
      profile: "My Profile",
      subscribers: "Readers",
      photo: "Photo",
      video: "Video",
      agreement:
        "I confirm my agreement with <1>the privacy policy</1> and <2>the terms of use</2>",

      system: {
        del_acc: "Delete account",
        loading: "Loading",
        no_new_posts: "No new posts",
        subscribe: "Subscribe",
        unsubscribe: "Unsubscribe",
        have_not_subs: "You have no subscriptions",
        unsubscribe_user: "Unsubscribe from user",
        unsubscribe_user_conf:
          "Are you sure you want to unsubscribe from this user?",
        cancel: "Cancel",
        yes: "Yes",
        copy: "Copy",
        share: "Share",
        compline: "Complain",
        translate: "Translate",
        show_original: "Show original",
        reply: "Reply",
        remove_post: "Remove post",
        remove_post_conf: "Are you sure you want to delete this post?",
        remove_from_favorite: "Are you sure you want to remove this post?",
        remove_from_favorite_conf:
          "Are you sure you want to remove from favorites?",
        send: "Send",
        copy: "Copy",
        remove: "Remove",
        publish: "Publish",
        add_comment: "Add comment",
        view_replies: "View replies",
        reply_to: "Reply to",
        delete_acc_from: "Delete account from",
        delete_acc_conf: "Do you really want to delete your account?",
        enter_email:
          "Type your E-mail and we will send you a temperer code to log in",
        send_email: "We sent you one-time password (OTP) to your E-mail.",
        enter_email: "Enter email and password",
        agree_politic: "Agree to the policy",
        registration: "Registration",
        report: "Report",
      },
      reports: {
        complaint: "Complaint about post",
        spam: "spam",
        nude: "Demonstration of naked body",
        cruelty: "Demonstration of cruelty",
        other: "Other",
        complaint_reason: "Write the reason for the complaint...",
      },
    },
  },
  uk: {
    langName: "Українська",
    translation: {
      auth: "Авторизація",
      login: "Логін",
      email: "Пошта",
      pass: "Пароль",
      create: "Створити аккаунт",
      forgot: "Забули пароль?",
      nickname: "Псевдонім",
      interface_lang: "Мова інтерфейсу",
      gender: "Ваша стать",
      man: "Чоловік",
      woman: "Жінка",
      it: "Не хочу вказувати",
      birthday: "Ваша дата народження",
      posts: "Дописів",
      followers: "Читачів",
      in_favorites: "В улюблених",
      available: "доступний з мобільного",
      edit_profile: "Редагувати профіль",
      logout: "Вийти",
      profile: "Мій профіль",
      posts: "Дописів",
      subscribers: "Читачів",
      agreement:
        "Підтверджую згоду з <1>політикою конфіденційності</1> та <2>умовами використання</2>",

      system: {
        del_acc: "Видалити акаунт",
        loading: "Завантаження",
        no_new_posts: "Немає нових постів",
        subscribe: "Підписатись",
        unsubscribe: "Відписатись",
        have_not_subs: "У вас немає підписок",
        unsubscribe_user: "Відписатися від користувача",
        unsubscribe_user_conf: "Дійсно відписатися від цього користувача?",
        cancel: "Скасувати",
        yes: "Так",
        translate: "Перекласти",
        show_original: "Показати оригінал",
        reply: "Відповісти",
        copy: "Скопіювати",
        share: "Поділитись",
        compline: "Поскаржитись",
        translate: "Перекласти",
        remove_post: "Видалити допис",
        remove_post_conf: "Дійсно видалити цей допис?",
        remove_from_favorite: "Дійсно видалити цей допис?",
        remove_from_conf: "Дійсно видалити з улюблених?",
        send: "Надіслати",
        copy: "Скопіювати",
        remove: "Видалити",
        publish: "Опубліковати",
        add_comment: "Додайте коментар",
        view_replies: "Переглянути відповіді",
        reply_to: "Відповісти",
        delete_acc_from: "Видалити акаунт з",
        delete_acc_conf: "Ви дійсно бажаєте видалити ваш акаунт?",
        enter_email:
          "Введіть свій E-mail, і ми надішлемо вам код темперера для входу",
        send_email:
          "Ми надіслали вам одноразовий пароль (OTP) на вашу електронну пошту.",
        enter_email: "Уведіть пошту та пароль",
        agree_politic: "Погодьтесь з політикою",
        registration: "Реєстрація",
        report: "Звіт",
      },
      reports: {
        complaint: "Скарга на допис",
        spam: "спам",
        nude: "Демонстрація оголеного тіла",
        cruelty: "Демонстрація жорстокості",
        other: "Інше",
        complaint_reason: "Напишіть причину скарги...",
      },
    },
  },
};

export default resources;
