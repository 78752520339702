import { apiRequest } from "./api.js";
const DEEPL_AUTH_KEY = process.env.REACT_APP_TRANSLATE;

export const actions = {
  getPosts: async (publisherId, page) => {
    try {
      const response = await apiRequest("get_posts.php", "GET", {
        publisher_id: publisherId == -1 ? "" : publisherId,
        page: page,
      });

      return response.posts;
    } catch (error) {
      console.log("error: ", error);
    }
  },
  getPost: async (publisherId, post_id) => {
    try {
      const response = await apiRequest("get_posts.php", "GET", {
        publisher_id: publisherId == -1 ? "" : publisherId,
        post_id: post_id,
      });

      return response.posts;
    } catch (error) {
      console.log("error: ", error);
    }
  },
  getSinglePosts: async (publisherId, page) => {
    try {
      const response = await apiRequest("get_posts.php", "GET", {
        publisher_id: publisherId == -1 ? "" : publisherId,
        page: page,
      });

      return response.posts;
    } catch (error) {
      console.log("error: ", error);
    }
  },
  likComment: async (commentId, isLiked) => {
    try {
      const response = await apiRequest("like_comment.php", "POST", {
        comment_id: commentId,
        isLiked,
      });

      if (response.status !== "success" || !response) {
        throw new Error("Failed to like/unlike comment");
      }

      return response;
    } catch (error) {
      console.error("Error liking/unliking comment:", error);
    }
  },
  removeComment: async (commentId) => {
    try {
      const response = await apiRequest("remove_comment.php", "POST", {
        comment_id: commentId,
        type: "text",
      });

      if (response.status !== "success") {
        throw new Error("Failed to remove comment");
      }

      return response;
    } catch (error) {
      console.error("Error removing comment:", error);
    }
  },
  auth: async () => {
    try {
      const response = await apiRequest("check_auth.php", "GET");

      if (!response.isAuthenticated) {
        return { auth: false, user: null };
      }

      return { auth: true, user: response.user };
    } catch (error) {
      console.log("error: ", error);
    }
  },
  getUser: async () => {
    try {
      const user = await apiRequest("edit_profile.php", "GET");

      if (!user.status === "success") {
        throw new Error("User not found");
      }

      return user;
    } catch (error) {
      console.log("error: ", error);
    }
  },
  likePost: async (postId, like) => {
    try {
      const response = await apiRequest("like_dislike_post.php", "POST", {
        post_id: postId,
        like_dislike: like ? "like" : "dislike",
      });

      console.log("response: ", response);
      return response;
    } catch (error) {
      console.error("Помилка при запиті до API:", error);
    }
  },
  setFavorite: async (postId) => {
    try {
      const result = await apiRequest("toggle_favorites.php", "POST", {
        post_id: postId,
      });

      if (!result.status == "success") {
        throw new Error("Set favorite not success");
      }

      return result;
    } catch (error) {}
  },
  detectLang: async (text) => {
    const url = "https://api-free.deepl.com/v2/translate";

    try {
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: new URLSearchParams({
          auth_key: DEEPL_AUTH_KEY,
          text: text,
          target_lang: "EN",
        }),
      });
      const data = await response.json();
      const lang = data.translations[0];

      return lang;
    } catch (error) {
      console.error("Error detecting language:", error);
    }
  },
};
