import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import "i18n";
import { setUser } from "features/auth/authSlice";
import { actions } from "utils/apiActions";
import Authorize from "components/Authorize/Authorize";
import DN from "components/DownloadNotification/DownloadNotification";
import Registration from "components/Registration/Registration";
import CameraViewer from "components/CameraViewer/CameraViewer";
import Main from "components/Main/Main";

export function Screen() {
  const [cameraOn, setCameraOn] = useState(false);
  const [registrationOn, setRegistrationOn] = useState(false);

  const { showAuthorize } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  const { i18n } = useTranslation();

  useEffect(() => {
    actions
      .auth()
      .then((user) => {
        dispatch(setUser(user.user));
      })
      .catch((e) => console.error(e));

    actions
      .getUser()
      .then((user) => i18n.changeLanguage(user.lang))
      .catch((e) => console.error(e));
  }, [dispatch]);

  return (
    <div>
      <DN />
      {showAuthorize && <Authorize setRegistrationOn={setRegistrationOn} />}
      {registrationOn && <Registration setRegistrationOn={setRegistrationOn} />}
      {cameraOn && <CameraViewer setCameraOn={setCameraOn} />}
      <Main setCameraOn={setCameraOn} />
    </div>
  );
}
