import axios from "axios";

export async function apiRequest(endpoint, method = "GET", data = {}) {
  const token = localStorage.getItem("token"); // Отримуємо токен з localStorage

  // Налаштування для запиту
  const config = {
    method: method,
    url: `https://letmestyle.com/api/v1/${endpoint}`,
    headers: {
      "Content-Type": "multipart/form-data",
      "X-Requested-With": "XMLHttpRequest",
      ...(token && { Authorization: `Bearer ${token}` }), // Додаємо токен до заголовків, якщо він є
    },
    withCredentials: true, // Дозволяє передавати кукі між різними доменами
    credentials: "include", // Включає облікові дані в запит
  };

  if (method === "POST") {
    config.data = data;
  } else {
    config.params = data;
  }

  try {
    const response = await axios(config);
    return response.data; // Повертаємо дані відповіді
  } catch (error) {
    console.error("API request error:", error);
    throw error;
  }
}

export async function fetchAllAudios(comments) {
  const blobs = [];
  for (let i = 0; i < comments.length; i++) {
    const response = await fetch(`https://letmestyle.com${comments[i].file}`);
    blobs.push(await response.blob());
  }
  console.log("blobs: ", blobs);
  return blobs;
}
