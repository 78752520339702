export const VoiceSvg = () => {
  return (
    <svg
      width="27px"
      height="27px"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 10500 15000"
    >
      <path
        d="M5233.73 109.25l0 0c1543.11,0 2805.63,1262.52 2805.63,2805.62l0 4546.17c0,1543.1 -1262.52,2805.62 -2805.63,2805.62l0 0c-1543.1,0 -2805.62,-1262.52 -2805.62,-2805.62l0 -4546.17c0,-1543.1 1262.52,-2805.62 2805.62,-2805.62zm-2063.74 13399.07l1371.56 0 0 -1012.13c-2485.58,-337.76 -4401.3,-2468.23 -4401.3,-5046.47 0,-669.27 0.04,-545.22 0.11,-1075.18 0,-330.39 341.16,-713.34 710.19,-713.34 369.36,0 671.53,356.49 671.53,713.34 -0.08,508.53 -0.14,380.7 -0.14,1089.16 0,2049.98 1661.84,3711.82 3711.82,3711.82 2049.98,0 3711.83,-1661.84 3711.83,-3711.82 0,-753.15 0.04,-617.8 0.08,-1089.16 0,-383.3 375.58,-713.34 702.29,-713.34 358.72,0 679.28,330.04 679.28,713.34l-0.05 1075.18c0,2576.32 -1912.88,4705.53 -4395.77,5045.7l0 1012.9 1366.08 0c392.91,0 714.37,321.46 714.37,714.37l0 0.01c0,392.91 -321.46,714.37 -714.37,714.37l-4127.51 0c-392.91,0 -714.37,-321.46 -714.37,-714.37l0 -0.01c0,-392.91 321.46,-714.37 714.37,-714.37z"
        fill="#ffffff"
      />
    </svg>
  );
};
