export const ShowSvg = () => {
  return (
    <svg
      className="hide_ui svg-shadow"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20500 14000"
      style={{ fillRule: "evenodd" }}
    >
      <path
        className="fil0"
        d="M10236.76 1692.32c5624.31,0 10061.53,5131.98 10183.71,5305.3 -147.88,145.19 -4559.4,5305.31 -10183.71,5305.31 -5624.31,0 -10040.55,-5124.32 -10183.71,-5305.31 118.55,-218.1 4559.4,-5305.3 10183.71,-5305.3zm-24.62 1274.46c2213.9,0 4008.62,1794.72 4008.62,4008.61 0,2213.9 -1794.72,4008.62 -4008.62,4008.62 -2213.89,0 -4008.61,-1794.72 -4008.61,-4008.62 0,-2213.89 1794.72,-4008.61 4008.61,-4008.61zm0 1911.69c1158.1,0 2096.93,938.83 2096.93,2096.92 0,1158.1 -938.83,2096.93 -2096.93,2096.93 -1158.1,0 -2096.92,-938.83 -2096.92,-2096.93 0,-1158.09 938.82,-2096.92 2096.92,-2096.92z"
      />
    </svg>
  );
};
