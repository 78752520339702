import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FavoriteSvg, ShareSvg, TextSvg, VoiceSvg } from "components/Svg";
import { setIsAuthorize } from "features/auth/authSlice";
import {
  toggleTextComment,
  setPostId,
} from "features/textComment/textCommentSlice";
import { handlePopAnimation } from "utils/handlePopAnimation";
import { actions } from "utils/apiActions";

export function RightControls({
  slide,
  setAudioComments,
  activeIndex,
  setIsShareBoxVisible,
}) {
  const [isFavorite, setIsFavorite] = useState(slide.is_favorite);
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const dispatch = useDispatch();

  const handleToggleFavorites = async (postId) => {
    if (!isAuthenticated) {
      dispatch(setIsAuthorize(true));
      return;
    }
    handlePopAnimation(".comments .favourites", activeIndex);

    const result = await actions.setFavorite(postId);

    if (result.status == "success") {
      setIsFavorite(result.is_favorite);
    }
  };

  const handleTextCommentClick = (postId) => {
    if (isAuthenticated) {
      dispatch(setPostId(postId));
      dispatch(toggleTextComment());
    } else {
      dispatch(setIsAuthorize(true));
    }
  };

  const handleShareClick = (postId) => {
    setIsShareBoxVisible(true);
  };

  return (
    <div className="right_i">
      <div className="comments" data-postid={slide.id}>
        <div
          className="comm_text_button ani"
          onClick={() => handleTextCommentClick(slide.id)}
        >
          <TextSvg />
          <span className="t-shadow">{slide.text_comments_count || 0}</span>
        </div>
        <div
          className="comm_voice_button ani"
          onClick={() => setAudioComments(true)}
        >
          <VoiceSvg />
          <span className="t-shadow">{slide.voice_comments_count || 0}</span>
        </div>
        <div
          className={`favourites ani ${isFavorite ? "active" : ""}`}
          onClick={() => handleToggleFavorites(slide.id)}
        >
          <FavoriteSvg />
        </div>
        <br />
        <div
          className="share ani"
          data-postid={`${slide.id}`}
          onClick={() => handleShareClick(slide.id)}
        >
          <ShareSvg />
        </div>
      </div>
    </div>
  );
}
