import { Avatar } from "components/UI";
import { CommentItem, SubComments } from ".";
import { useSelector } from "react-redux";

export const CommentWrapper = ({
  comment,
  level = 1,
  comments,
  setComments,
}) => {
  const commentClass = level === 1 ? "comment" : "comment level2";
  const user = useSelector((state) => state.auth.user);
  const userId = user ? user.id : JSON.parse(localStorage.getItem("user"))?.id;

  return (
    <div className={commentClass} key={comment.id}>
      <Avatar userId={comment.user_id} avatar={comment.avatar} />
      <div className="comment_data">
        <CommentItem
          comment={comment}
          userId={userId}
          setComments={setComments}
        />
        <SubComments
          parentId={comment.id}
          comments={comments}
          setComments={setComments}
        />
      </div>
    </div>
  );
};
