import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { apiRequest } from "utils/api";
import { toggleTextComment } from "features/textComment/textCommentSlice";
import { SubmitBtnSvg, BackArrowBtnSvg } from "../../Svg";
import { CommentsList } from ".";

export function TextComment() {
  const dispatch = useDispatch();
  const postId = useSelector((state) => state.textComment.postId);
  const [comments, setComments] = useState([]);
  const [error, setError] = useState(null);
  const [newCommentText, setNewCommentText] = useState(""); // Стан для збереження тексту нового коментаря
  const showTextComment = useSelector(
    (state) => state.textComment.showTextComment
  );
  const { t } = useTranslation();

  useEffect(() => {
    if (!postId) return; // Перевірка, чи переданий postId

    // Запит для отримання коментарів з поста
    apiRequest("get_text_comments.php", "GET", { post_id: postId })
      .then((data) => {
        if (data.status === "error") {
          setError(
            "This post has been removed, or you do not have permission to view it"
          );
        } else if (data.comments && data.comments.message === "No comments") {
          setError("No comments available");
        } else if (Array.isArray(data.comments) && data.comments.length === 0) {
          setError("No comments available");
        } else if (!Array.isArray(data.comments)) {
          setError("An error occurred while loading comments.");
        } else {
          setComments(data.comments);
          setError(null);
        }
      })
      .catch((error) => {
        console.error("Error loading comments:", error);
        setError("An error occurred while loading comments.");
      });
  }, [postId]);

  useEffect(() => {
    const comments = document.querySelector(".text_messages");

    if (comments) {
      if (showTextComment) {
        comments.style.animation = "slide-in 300ms ease-in-out forwards";
      } else {
        comments.style.animation = "slide-out 300ms ease-in-out forwards";
        setTimeout(() => {
          // Reset the state to hide the component after the slide-out animation
          dispatch(toggleTextComment());
        }, 300);
      }
    }
  }, [showTextComment, dispatch]);

  const handleClose = () => {
    const comments = document.querySelector(".text_messages");

    if (comments) {
      comments.style.animation = "slide-top ease-in-out 300ms forwards reverse";

      setTimeout(() => {
        dispatch(toggleTextComment());
      }, 300);
    }
  };

  // Функція для надсилання нового коментаря
  const handleSendComment = (e) => {
    e.preventDefault();
    const replyToId =
      parseInt(document.getElementById("reply_to_id").value, 10) || 0;

    if (!newCommentText.trim()) {
      alert("Please enter a comment");
      return;
    }

    // Відправка коментаря на сервер
    apiRequest("add_comment.php", "POST", {
      post_id: postId,
      text: newCommentText,
      parent_id: replyToId,
    })
      .then((data) => {
        if (data.status === "success") {
          // Додавання нового коментаря в список
          const newComment = {
            id: data.comment.id,
            text: newCommentText,
            time: "0m", // Новий коментар додається з часом "0 хвилин тому"
            user_id: data.comment.user_id,
            avatar: data.comment.avatar || "/img/icons/default_avatar.png",
            nickname: data.comment.nickname,
            like_count: 0,
            isLiked: false,
            parent: replyToId,
          };

          // Додаємо новий коментар до масиву
          setComments((prevComments) => [...prevComments, newComment]);

          setNewCommentText(""); // Очистка поля введення
          document.querySelector(".reply_to").style.display = "none"; // Сховати блок відповіді
        } else {
          alert("Failed to add comment");
        }
      })
      .catch((error) => {
        console.error("Error sending comment:", error);
      });
  };

  return (
    <div>
      <div
        className="comments_block text_messages"
        style={{ zIndex: 100, visibility: "visible", top: "0px" }}
      >
        <div className="top_nav">
          <div className="button_left">
            <BackArrowBtnSvg handleClose={handleClose} />
          </div>
          <div
            className="button_right"
            style={{ alignItems: "center", padding: "0 7px" }}
          ></div>
        </div>
        <div className="comments_list">
          <input type="hidden" id="comment_post_id" value={postId} />
          <CommentsList
            comments={comments}
            error={error}
            setComments={setComments}
          />
        </div>
        <div className="reply_to">
          <input type="hidden" id="reply_to_id" />
          {t("system.reply_to")} <b></b>
          <span className="cancel">{t("system.cancel")}</span>
        </div>
        <div className="send_comment">
          <form onSubmit={handleSendComment}>
            <input
              type="text"
              id="send_comment_text"
              placeholder="Your comment"
              value={newCommentText}
              onChange={(e) => {
                setNewCommentText(e.target.value);
              }}
            />
            <button type="submit" className="send b-grad radius-max">
              <SubmitBtnSvg />
            </button>
          </form>
        </div>
      </div>
    </div>
  );
}
