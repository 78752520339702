import { useDispatch } from "react-redux";
import {
  setUser,
  toggleUserProfile,
} from "features/userProfile/userProfileSlice";

const DEFAULT_AVATAR =
  process.env.REACT_APP_IMG_DOMAIN + "/img/icons/default_avatar.png";

export const Avatar = ({ userId, avatar = DEFAULT_AVATAR }) => {
  const dispatch = useDispatch();

  const handleUserProfileClick = (userId) => {
    dispatch(setUser(userId));
    dispatch(toggleUserProfile());
  };

  return (
    <div
      className="avatar user_profile"
      data-userid={userId}
      onClick={() => handleUserProfileClick(userId)}
    >
      <img src={avatar} alt="User Avatar" />
    </div>
  );
};
