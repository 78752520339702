export const FavoriteSvg = () => {
  return (
    <svg
      width="27"
      height="27"
      className="svg-shadow"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 2601 2501"
    >
      <defs>
        <linearGradient id="favourite-gradient" gradientTransform="rotate(30)">
          <stop offset="0%" stopColor="#e70489"></stop>
          <stop offset="100%" stopColor="#fa5f6f"></stop>
        </linearGradient>
      </defs>
      <path d="M1302.19 3.25c98.33,-0.07 130.5,71.25 195.75,210.87l253.03 541.42c196.72,24.86 393.44,49.72 590.16,74.58 152.33,19.25 229.37,37.58 253.45,113.53 24.08,75.94 -21.6,156.51 -134.18,262.66l-432.07 407.38c36.87,193.63 73.73,387.26 110.6,580.9 29.27,153.72 29.3,238.09 -30.67,281.44 -59.97,43.36 -155.54,28.07 -292.55,-47.76l-513.52 -284.22c-171.08,94.69 -342.15,189.37 -513.23,284.06 -137.1,75.88 -235.41,89.96 -288.88,51.46 -53.47,-38.5 -63.97,-130.91 -34.73,-284.46l110.7 -581.42c-143.66,-135.44 -287.3,-270.89 -430.95,-406.33 -112.95,-106.5 -162.11,-173.82 -138.46,-262.54 23.64,-88.72 105.74,-95.64 258.81,-114.98l587.96 -74.3c84.3,-180.38 168.6,-360.76 252.9,-541.14 65.29,-139.72 97.55,-211.08 195.88,-211.15z"></path>
    </svg>
  );
};
