import { createSlice } from "@reduxjs/toolkit";

export const authSlice = createSlice({
  name: "auth",
  initialState: {
    user: null, // Зберігає дані користувача після успішної авторизації
    isAuthenticated: false, // Визначає, чи користувач авторизований
    showAuthorize: false, // Контролює відображення компонента авторизації
    showSettings: false, // Контролює відображення компонента налаштувань
  },
  reducers: {
    toggleAuthorize: (state) => {
      state.showAuthorize = !state.showAuthorize; // Перемикає стан showAuthorize
    },
    setIsAuthorize: (state, { payload }) => {
      state.showAuthorize = payload; // Перемикає стан showAuthorize
    },
    setUser: (state, action) => {
      state.user = action.payload;
      if (action.payload) {
        state.isAuthenticated = true;
      } else {
        state.isAuthenticated = false;
      }
      localStorage.setItem("user", JSON.stringify(action.payload));
    },
    clearUser: (state) => {
      state.user = null; // Очищає дані користувача
      state.isAuthenticated = false; // Встановлює статус авторизації на "не авторизований"
      //state.showSettings = false; // Приховує компонент налаштувань

      // Видаляємо дані користувача з localStorage
      localStorage.removeItem("token");
      console.log("clearUser...");
    },
    toggleSettings: (state) => {
      console.log(state.showSettings); // Correct property name
      state.showSettings = !state.showSettings; // Перемикає стан showSettings
      console.log("Toggling settings");
      console.log(state.showSettings); // Correct property name
    },
  },
});

export const {
  toggleAuthorize,
  setUser,
  clearUser,
  toggleSettings,
  setIsAuthorize,
} = authSlice.actions;
export default authSlice.reducer;
