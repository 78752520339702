export const HideSvg = () => {
  return (
    <svg
      className="unhide_ui svg-shadow"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20500 14000"
      style={{ fillRule: "evenodd" }}
    >
      <path
        className="fil0"
        d="M10236.76 1692.32c5624.31,0 10061.53,5131.98 10183.71,5305.3 -85.28,83.73 -1588.7,1835.24 -3924.79,3303.14l-2385.91 -2385.91c72.38,-301.37 110.99,-615.89 110.99,-939.46 0,-2213.89 -1794.72,-4008.61 -4008.62,-4008.61 -323.57,0 -638.08,38.61 -939.45,110.99l-1151.26 -1151.26c680.88,-148.59 1388.09,-234.19 2115.33,-234.19zm-5932.48 -1600.24l2284.57 2284.57 9.24 -3.47 1316.94 1316.94 -7.43 5.28 1391.76 1391.76 8.53 -4.17 2796.66 2796.66 -4.17 8.53 1391.76 1391.76 5.28 -7.42 1740.83 1740.83 -8.45 4.25 1900.58 1900.58 -985.01 985.01 -2285.24 -2285.24c-1120.51,416.33 -2340.33,684.98 -3623.37,684.98 -5624.31,0 -10040.55,-5124.32 -10183.71,-5305.31 79.84,-146.88 2120.44,-2501.91 5162.49,-4024.27l-1896.26 -1896.26 985 -985.01zm8201.25 10171.27l-1393.49 -1393.5c-272.68,129.78 -577.8,202.47 -899.9,202.47 -1158.1,0 -2096.92,-938.83 -2096.92,-2096.93 0,-322.1 72.69,-627.21 202.47,-899.89l-1393.5 -1393.5c-454.18,649.78 -720.66,1440.44 -720.66,2293.39 0,2213.9 1794.72,4008.62 4008.61,4008.62 852.95,0 1643.61,-266.48 2293.39,-720.66zm-918.36 -4871.1c74.15,64.46 143.73,134.03 208.19,208.19l-208.19 -208.19z"
      />
    </svg>
  );
};
