import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import "./ShareLightbox.css";

function ShareLightbox({ onClose }) {
  const [copied, setCopied] = useState(false);
  const { t } = useTranslation();

  const textToCopy = window.location.href;
  const handleCopy = async () => {
    try {
      await navigator.clipboard.writeText(textToCopy);
      setCopied(true);
      setTimeout(() => setCopied(false), 2000);
      onClose();
    } catch (err) {
      console.error("Помилка копіювання", err);
    }
  };

  return (
    <div className="lightbox share-box">
      <div className="box">
        <div className="header">
          <span>{t("system.share")}</span>
          <div className="close_share_box" onClick={onClose}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 501 501">
              <path
                className="fil0"
                d="M37.29 6.53l212.97 212.96 212.97 -212.98c8.44,-8.43 22.24,-8.43 30.67,0l0.02 0.03c8.45,8.43 8.45,22.24 0,30.67l-212.96 212.97 212.96 212.97c8.44,8.44 8.44,22.24 0,30.67l-0.02 0.02c-8.43,8.45 -22.24,8.45 -30.67,0l-212.97 -212.96 -212.96 212.95c-8.44,8.45 -22.24,8.45 -30.68,0l-0.01 -0.01c-8.45,-8.44 -8.45,-22.24 0,-30.67l212.95 -212.97 -212.95 -212.96c-8.45,-8.44 -8.45,-22.24 0,-30.68l0.01 -0.01c8.44,-8.45 22.24,-8.45 30.67,0z"
              />
            </svg>
          </div>
        </div>
        <div className="content">
          <div className="url">{textToCopy}</div>
          <div>
            <div
              className="button submit_share"
              onClick={handleCopy}
              variant="outline"
            >
              {t("system.copy")}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ShareLightbox;
