import React, { useCallback, useState } from "react";
import { apiRequest } from "utils/api";
import { useTranslation, Trans } from "react-i18next";
import './registration.css';
import { Toaster, toast } from 'react';
import "./registration.css";

function Registration({ setRegistrationOn }) {
  const { t } = useTranslation();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [agreement, setAgreement] = useState(false);
  const [error, setError] = useState("");

  const handleRegistration = useCallback(async () => {
    try {
      if (!email || !password) throw new Error(t("system.enter_email"));
      if (!agreement) throw new Error(t("system.agree_politic"));

      // Перевірка формату Email
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailRegex.test(email)) {
        throw new Error('Невірний формат e-mail');
      }

      const response = await apiRequest("reg.php", "POST", {
        inputEmail: email,
        inputPassword: password,
        agreement: "Y",
      });

      if (response.status === "error") throw new Error(response.message);

      setRegistrationOn(false);
    } catch (error) {
      setError(error.message);
    }
  }, [email, password, agreement]);

  return (
    <div id="registration" style={{ zIndex: 9 }}>
      <div className="top_nav">
        <div className="button_right" onClick={() => setRegistrationOn(false)}>
          <svg
            className="close"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 501 501"
          >
            <path
              className="fil0"
              d="M37.29 6.53l212.97 212.96 212.97 -212.98c8.44,-8.43 22.24,-8.43 30.67,0l0.02 0.03c8.45,8.43 8.45,22.24 0,30.67l-212.96 212.97 212.96 212.97c8.44,8.44 8.44,22.24 0,30.67l-0.02 0.02c-8.43,8.45 -22.24,8.45 -30.67,0l-212.97 -212.96 -212.96 212.95c-8.44,8.45 -22.24,8.45 -30.68,0l-0.01 -0.01c-8.45,-8.44 -8.45,-22.24 0,-30.67l212.95 -212.97 -212.95 -212.96c-8.45,-8.44 -8.45,-22.24 0,-30.68l0.01 -0.01c8.44,-8.45 22.24,-8.45 30.67,0z"
            />
          </svg>
        </div>
      </div>
      <h2>{t("system.registration")}</h2>
      <form autoComplete="off">
        <input
          name="text"
          id="reg_email"
          type="text"
          placeholder={t("email")}
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <input
          name="password"
          id="reg_password"
          type="password"
          placeholder={t("pass")}
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <input
          type="checkbox"
          id="agreement"
          checked={agreement}
          onChange={(event) => setAgreement(event.target.checked)}
        />
        <label htmlFor="agreement">
          <Trans i18nKey="agreement">
            <span>
              Підтверджую згоду з{" "}
              <a
                href="https://docs.google.com/document/d/1MrkkiOzmrFsZXqFudXYOxNOSVRrs58f_wAnpcAMTBmw/edit"
                target="_blank"
                rel="noopener noreferrer"
              >
                політикою конфіденційності
              </a>{" "}
              та{" "}
              <a
                href="https://docs.google.com/document/d/1VAArlPXcohw8MiDUNPPq6sqGg-zeoFaqIqZOBE-BkuE/edit"
                target="_blank"
                rel="noopener noreferrer"
              >
                умовами використання
              </a>
            </span>
          </Trans>
        </label>
        {error && <span id="reg_error">{error}</span>}
        <button
          type="button"
          onClick={handleRegistration}
          className="accept shadow radius-max b-grad"
        >
          <span>{t("system.registration")}</span>
        </button>
      </form>
    </div>
  );
}

export default Registration;