export const SettingsMenuSvg = ({ handleSettingsClick }) => {
  return (
    <svg
      className="sett svg-shadow"
      onClick={handleSettingsClick}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 2500 12000"
      style={{ fillRule: "evenodd", clipRule: "evenodd" }}
    >
      <path
        className="fil0"
        d="M1262.94 235.68c674.15,0 1220.66,546.51 1220.66,1220.67 0,674.15 -546.51,1220.66 -1220.66,1220.66 -674.16,0 -1220.67,-546.51 -1220.67,-1220.66 0,-674.16 546.51,-1220.67 1220.67,-1220.67zm0 9180.52c674.15,0 1220.66,546.51 1220.66,1220.66 0,674.16 -546.51,1220.67 -1220.66,1220.67 -674.16,0 -1220.67,-546.51 -1220.67,-1220.67 0,-674.15 546.51,-1220.66 1220.67,-1220.66zm0 -4590.28c674.15,0 1220.66,546.51 1220.66,1220.67 0,674.15 -546.51,1220.66 -1220.66,1220.66 -674.16,0 -1220.67,-546.51 -1220.67,-1220.66 0,-674.16 546.51,-1220.67 1220.67,-1220.67z"
      />
    </svg>
  );
};
