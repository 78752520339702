import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import translate from "deepl";
import { actions } from "utils/apiActions";
import { LikeHeart } from "./LikeHeart";

export const CommentItem = ({ comment, userId, setComments }) => {
  const [currentComment, setCurrentComment] = useState(null);
  const [translatedComment, setTranslatedComment] = useState(null);
  const [isOriginalText, setIsOriginalText] = useState(true);
  const [isLocal, setIsLocal] = useState(true);
  const { t, i18n } = useTranslation();

  const DEEPL_AUTH_KEY = process.env.REACT_APP_TRANSLATE;
  const current_lang = i18n.language;

  useEffect(() => {
    if (!comment.msg_lang) comment.msg_lang = "uk";
    setCurrentComment(comment);

    if (
      currentComment?.msg_lang.toLowerCase() !== current_lang?.toLowerCase()
    ) {
      setIsLocal(false);
    }
  }, [current_lang, currentComment]);

  const handleTranslate = async (text) => {
    if (!isOriginalText) {
      setIsOriginalText(true);
      return;
    }
    translate({
      free_api: true,
      text: text,
      target_lang: current_lang,
      auth_key: DEEPL_AUTH_KEY,
    })
      .then((result) => {
        const translated_text = result.data.translations[0].text;
        setTranslatedComment(translated_text);
        setIsOriginalText(false);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handleReplyClick = (commentId, nickname) => {
    document.getElementById("reply_to_id").value = commentId;
    document.querySelector(".reply_to b").textContent = nickname;
    document.getElementById("send_comment_text").focus();
    document.querySelector(".reply_to").style.display = "block";
  };

  const handleRemoveComment = async (commentId) => {
    const data = await actions.removeComment(commentId);

    setComments((prevComments) =>
      prevComments.filter((comment) => comment.id !== commentId)
    );
  };

  if (!currentComment) {
    return null;
  }

  return (
    <>
      <div className="nickname">{currentComment.nickname}</div>
      <div className="time">{currentComment.time}</div>
      <div className="text">
        {isOriginalText ? currentComment.text : translatedComment}
      </div>

      <div
        className="w100 actions"
        data-comment_id={currentComment.id}
        data-reply_to_nickname={currentComment.nickname}
      >
        <div
          className="ans"
          onClick={() =>
            handleReplyClick(currentComment.id, currentComment.nickname)
          }
        >
          {t("system.reply")}
        </div>
        {Number(userId) === Number(currentComment.user_id) && (
          <div onClick={() => handleRemoveComment(currentComment.id)}>
            {t("system.remove")}
          </div>
        )}
        {!isLocal && (
          <div onClick={() => handleTranslate(currentComment.text)}>
            {isOriginalText ? t("system.translate") : t("system.show_original")}
          </div>
        )}
        <div>
          <LikeHeart comment={comment} />
        </div>
      </div>
    </>
  );
};
