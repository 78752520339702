import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { actions } from "utils/apiActions";
import { handlePopAnimation } from "utils/handlePopAnimation";
import { DislikeSvg, LikeSvg } from "components/Svg";
import { setIsAuthorize } from "features/auth/authSlice";

export function LikesSection({ slide, activeIndex }) {
  const [isLiked, setIsLiked] = useState(slide.is_liked);
  const [isDisLiked, setIsDisLiked] = useState(slide.is_disliked);
  const [likeCount, setLikeCount] = useState(slide.like_count);

  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const dispatch = useDispatch();

  const handleLikeClick = async (postId) => {
    if (!isAuthenticated) {
      dispatch(setIsAuthorize(true));
      return;
    }
    handlePopAnimation(".like_buttons .like", activeIndex);

    const response = await actions.likePost(postId, true);

    if (response.status === "success") {
      setLikeCount(response.like_count);
      setIsLiked((prev) => !prev);
    }
  };

  const handleDislikeClick = async (postId) => {
    if (!isAuthenticated) {
      dispatch(setIsAuthorize(true));
      return;
    }

    handlePopAnimation(".like_buttons .dislike", activeIndex);

    const response = await actions.likePost(postId, false);

    if (response.status === "success") {
      setLikeCount(response.like_count);
      setIsDisLiked(response.is_disliked);
    }
  };

  return (
    <div className="like_buttons" data-postid="{slide.id}">
      <div className="like_count">{likeCount || 0}</div>
      <div
        className={`like ani ${isLiked ? "like_liked" : ""}`}
        onClick={async () => {
          if (isDisLiked) await handleDislikeClick(slide.id);
          handleLikeClick(slide.id);
        }}
      >
        <LikeSvg />
      </div>
      <div
        className={`dislike ani ${isDisLiked ? "dislike_liked" : ""}`}
        onClick={async () => {
          if (isLiked) await handleLikeClick(slide.id);
          handleDislikeClick(slide.id);
        }}
      >
        <DislikeSvg slide={slide} />
      </div>
    </div>
  );
}
